import { createStore, applyMiddleware, combineReducers } from 'redux'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from '@redux-devtools/extension'
import storage from 'redux-persist/lib/storage'
import auth from 'mp-auth/stores/reducer'
import data from './data/reducer'
import modal from './modal/reducer'
import view from './view/reducer'
import socket from './socket/reducer'

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const combinedReducer = combineReducers({
  auth,
  data,
  modal,
  view,
  socket
})

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    }
    if (state.count.count) {
      nextState.count.count = state.count.count
    } // preserve count value on client side navigation
    return nextState
  }
  return combinedReducer(state, action)
}

const store = configureStore({ reducer })

// eslint-disable-next-line import/no-mutable-exports
export let globalStore = null

const makeStore = ({ isServer }) => {
  if (globalStore) {
    return globalStore
  }
  if (isServer) {
    // If it's on server side, create a store
    return createStore(combinedReducer, bindMiddleware([thunkMiddleware]))
  }
  // If it's on client side, create a store which will persist
  const persistConfig = {
    key: 'tennders-persist',
    whitelist: ['auth'], // only counter will be persisted, add other reducers if needed
    storage // if needed, use a safer storage
  }
  const persistedReducer = persistReducer(persistConfig, combinedReducer) // Create a new reducer with our existing reducer
  const store = createStore(
    persistedReducer,
    bindMiddleware([thunkMiddleware])
  ) // Creating the store again
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.__persistor = persistStore(store) // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability featur
  globalStore = store
  return store
}

export const getStoreInstance = () => {
  if (!globalStore) {
    makeStore({ isServer: false })
  }
  return globalStore
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const wrapper = createWrapper(makeStore)

export type RootState = ReturnType<typeof store.getState>
