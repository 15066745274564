import { REDUX_AUTH_TYPES } from './action'

const initialState = { IS_LOGOUT: false }

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_AUTH_TYPES.USER:
      return {
        ...state,
        user: action.payload
      }
    case REDUX_AUTH_TYPES.IS_LOGOUT:
      return {
        ...state,
        isLogout: action.payload
      }
    default:
      return state
  }
}
