export const REDUX_VIEW_TYPES = {
  USER_MENU_VISIBLE: 'USER_MENU_VISIBLE',
  IS_LOADING: 'IS_LOADING',
  TOAST: 'TOAST',
  STATUS_DRAGGED: 'STATUS_DRAGGED',
  STATUS_DROPPED: 'STATUS_DROPPED',
  MULTI_SELECT_MODE: 'MULTI_SELECT_MODE',
  SHARE_MODE: 'SHARE_MODE',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS'
}

export const setUserMenuVisible = (isVisible) => (dispatch) => dispatch({
  type: REDUX_VIEW_TYPES.USER_MENU_VISIBLE,
  payload: isVisible
})

export const setLoading = (isLoading) => (dispatch) => dispatch({
  type: REDUX_VIEW_TYPES.IS_LOADING,
  payload: isLoading
})

export const dispatchToast = (toast) => (dispatch) => dispatch({
  type: REDUX_VIEW_TYPES.TOAST,
  payload: toast
})

export const dispatchMultiSelectMode = (mode) => (dispatch) => dispatch({
  type: REDUX_VIEW_TYPES.MULTI_SELECT_MODE,
  payload: mode
})

export const dispatchToggleFilters = (state) => (dispatch) => dispatch({
  type: REDUX_VIEW_TYPES.TOGGLE_FILTERS,
  payload: state
})
