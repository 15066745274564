export const REDUX_SOCKET_TYPES = {
  REFRESH: 'REFRESH',
  NEW_NOTIF: 'NEW_NOTIF',
  HOT_NOTIF: 'HOT_NOTIF'
}
export const REFRESH_TYPES = {
  TRANSACTION: 'transaction',
  NOTIF: 'notif',
  OFFER: 'offer',
  TLOG_LOAD: 'tlog-load',
  TLOG_TRUCK: 'tlog-truck',
  TRANSACTION_OFFERS: 'transaction-offer',
  ALL: 'all'

}
export const dispatchRefresh = (data) => (dispatch) => dispatch({
  type: REDUX_SOCKET_TYPES.REFRESH,
  payload: data
})

export const dispatchNewNotif = (data) => (dispatch) => dispatch({
  type: REDUX_SOCKET_TYPES.NEW_NOTIF,
  payload: data
})

export const dispatchHotNotification = (data) => (dispatch) => dispatch({
  type: REDUX_SOCKET_TYPES.HOT_NOTIF,
  payload: data
})
