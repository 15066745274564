import { REDUX_VIEW_TYPES } from './action'

const initialState = {
  userMenuVisible: false,
  dragResult: false,
  statusDragged: false,
  statusDropped: false,
  listMode: 'list',
  columnType: 'date',
  userMode: 'broker',
  multiSelectMode: false,
  assignTeamMode: false,
  multiEditMode: false,
  isFiltersVisible: false,
  isToggleGroupActive: false,
  resetToDefaultSort: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_VIEW_TYPES.USER_MENU_VISIBLE:
      return {
        ...state,
        isUserMenuVisible: action.payload
      }
    case REDUX_VIEW_TYPES.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case REDUX_VIEW_TYPES.TOAST:
      return {
        ...state,
        toast: action.payload
      }
    case REDUX_VIEW_TYPES.STATUS_DRAGGED:
      return {
        ...state,
        statusDragged: action.payload
      }
    case REDUX_VIEW_TYPES.STATUS_DROPPED:
      return {
        ...state,
        statusDropped: action.payload
      }
    case REDUX_VIEW_TYPES.MULTI_SELECT_MODE:
      return {
        ...state,
        multiSelectMode: action.payload
      }
    case REDUX_VIEW_TYPES.TOGGLE_FILTERS:
      return {
        ...state,
        isFiltersVisible: action.payload
      }
    default:
      return state
  }
}
