export const REDUX_MODAL_TYPES = {
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  CONFIRM_ACTION: 'CONFIRM_ACTION',
  COMPANY_TYPE: 'COMPANY_TYPE',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  REMINDER_MESSAGE: 'REMINDER_MESSAGE',
  SUBSCRIPTION_WARNING: 'SUBSCRIPTION_WARNING',
  SAVE_FILTER: 'SAVE_FILTER'
}

export const dispatchAddDocument = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.ADD_DOCUMENT,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchTermsAndConditions = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.TERMS_AND_CONDITIONS,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchReminderMessage = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.REMINDER_MESSAGE,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchConfirmActionModal = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.CONFIRM_ACTION,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchCompanyTypeModal = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.COMPANY_TYPE,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchSubscriptionWarningModal = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.SUBSCRIPTION_WARNING,
      payload: item
    })
  } catch {
    // To-do catch
  }
}

export const dispatchSaveFilterModal = (item:any, dispatch:any) => {
  try {
    dispatch({
      type: REDUX_MODAL_TYPES.SAVE_FILTER,
      payload: item
    })
  } catch {
    // To-do catch
  }
}
