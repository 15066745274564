import { REDUX_MODAL_TYPES } from './action'

const initialState = {
  ADD_DOCUMENT: false,
  TERMS_AND_CONDITIONS: false,
  REMINDER_MESSAGE: false,
  CONFIRM_ACTION: false,
  COMPANY_TYPE: false,
  SUBSCRIPTION_WARNING: false,
  SAVE_FILTER: false
}

export default function reducer(state = initialState, action:any) {
  switch (action.type) {
    case REDUX_MODAL_TYPES.ADD_DOCUMENT:
      return {
        ...state,
        addDocument: action.payload
      }
    case REDUX_MODAL_TYPES.TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: action.payload
      }
    case REDUX_MODAL_TYPES.REMINDER_MESSAGE:
      return {
        ...state,
        reminderMessage: action.payload
      }
    case REDUX_MODAL_TYPES.CONFIRM_ACTION:
      return {
        ...state,
        confirmAction: action.payload
      }
    case REDUX_MODAL_TYPES.COMPANY_TYPE:
      return {
        ...state,
        companyType: action.payload
      }
    case REDUX_MODAL_TYPES.SUBSCRIPTION_WARNING:
      return {
        ...state,
        subscriptionWarning: action.payload
      }
    case REDUX_MODAL_TYPES.SAVE_FILTER:
      return {
        ...state,
        saveFilterModal: action.payload
      }
    default:
      return state
  }
}
