export const REDUX_DATA_TYPES = {
  SELECTED_USER_ROWS: 'SELECTED_USER_ROWS',
  SELECTED_COMPANY_ROWS: 'SELECTED_COMPANY_ROWS',
  SELECTED_CHANNEL_ROWS: 'SELECTED_CHANNEL_ROWS',
  COUNTRY_LIST: 'COUNTRY_LIST',
  COUNTRY_FLAG_ENDPOINT: 'COUNTRY_FLAG_ENDPOINT',
  OPTIONS: 'OPTIONS',
  SCROLL_TO_TOP: 'SCROLL_TO_TOP'
}

export const dispatchOptions = (list, dispatch) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.OPTIONS,
      payload: list
    })
  } catch {
    // To-do catch
  }
}

export const dispatchCountryList = (list, dispatch) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.COUNTRY_LIST,
      payload: list
    })
  } catch {
    // To-do catch
  }
}

export const dispatchCountryFlagEndpoint = (endpoint, dispatch) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.COUNTRY_FLAG_ENDPOINT,
      payload: endpoint
    })
  } catch {
    // To-do catch
  }
}

export const dispatchSelectedUserRows = (selectedRows:any, dispatch: any) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.SELECTED_USER_ROWS,
      payload: selectedRows
    })
  } catch {
    // To-do catch
  }
}
export const dispatchSelectedCompanyRows = (selectedRows:any, dispatch: any) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.SELECTED_COMPANY_ROWS,
      payload: selectedRows
    })
  } catch {
    // To-do catch
  }
}

export const dispatchSelectedChannelRows = (selectedRows:any, dispatch: any) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.SELECTED_CHANNEL_ROWS,
      payload: selectedRows
    })
  } catch {
    // To-do catch
  }
}

export const dispatchScrollToTop = (value:any, dispatch: any) => {
  try {
    dispatch({
      type: REDUX_DATA_TYPES.SCROLL_TO_TOP,
      payload: value
    })
  } catch {
    // To-do catch
  }
}
