import React from 'react'
import { ceil } from 'lodash'

type Props = {
  size ?: number,
  color ?: string,
  zIndex ?: number,
  customLoaderClassName ?: string
  customStyle ?: React.CSSProperties,
  loaderStyle ?: React.CSSProperties,
}

const Loader = ({ size = 14, color, customLoaderClassName, zIndex, customStyle, loaderStyle } : Props) => (
  <div
    className="pos-rel w-100 m-5"
    style={{ minHeight: ceil(size * 1.2), zIndex: zIndex || 1000, ...(customStyle) }}
  >
    <div
      style={{ fontSize: (size || 14), color: color || '', ...(loaderStyle) }}
      className={customLoaderClassName || 'mp-loading'}
    >
      <i style={{ fontSize: (size || 14) }} className="fa fa-spinner fa-spin" />
    </div>
  </div>

)

export default React.memo(Loader)
