import { REDUX_VIEW_TYPES } from 'mp-common/stores/view/action'
import { REDUX_DATA_TYPES } from './action'

const initialState = {
  carriers: [],
  shippers: [],
  teams: [],
  selectedUserRows: {},
  selectedCompanyRows: {},
  selectedChannelRows: {},
  countryFlagEndpoint: '',
  countries: [],
  options: null,
  showScrollToTop: false
}

export default function reducer(state = initialState, action:any) {
  switch (action.type) {
    case REDUX_DATA_TYPES.OPTIONS:
      return {
        ...state,
        options: action.payload
      }
    case REDUX_DATA_TYPES.COUNTRY_LIST:
      return {
        ...state,
        countries: action.payload
      }
    case REDUX_DATA_TYPES.SCROLL_TO_TOP:
      return {
        ...state,
        showScrollToTop: action.payload
      }
    case REDUX_DATA_TYPES.COUNTRY_FLAG_ENDPOINT:
      return {
        ...state,
        countryFlagEndpoint: action.payload
      }
    case REDUX_DATA_TYPES.SELECTED_USER_ROWS:
      return {
        ...state,
        selectedUserRows: {
          ...state.selectedUserRows,
          ...action.payload
        }
      }
    case REDUX_DATA_TYPES.SELECTED_COMPANY_ROWS:
      return {
        ...state,
        selectedCompanyRows: {
          ...state.selectedCompanyRows,
          ...action.payload
        }
      }
    case REDUX_DATA_TYPES.SELECTED_CHANNEL_ROWS:
      return {
        ...state,
        selectedChannelRows: {
          ...state.selectedChannelRows,
          ...action.payload
        }
      }
    case REDUX_VIEW_TYPES.SHARE_MODE:
      return {
        ...state,
        selectedRows: []
      }
    default:
      return state
  }
}
