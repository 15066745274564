export const REDUX_AUTH_TYPES = {
  USER: 'USER',
  IS_LOGOUT: 'IS_LOGOUT'
}

export const dispatchLogout = (isLogout, dispatch) => {
  try {
    dispatch({
      type: REDUX_AUTH_TYPES.IS_LOGOUT,
      payload: isLogout
    })
  } catch {
    // To-do catch
  }
}

export const dispatchUser = (user, dispatch) => {
  if (user) {
    dispatchLogout(false, dispatch)
  }
  try {
    dispatch({
      type: REDUX_AUTH_TYPES.USER,
      payload: user
    })
  } catch (error) {
    // To-do catch
  }
}
