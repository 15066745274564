
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { wrapper } from 'mp-common/stores/store';
import 'styles/style.scss';
import NextNProgress from 'nextjs-progressbar';
import Loader from 'mp-common/components/Loader';
import Pusher from 'pusher-js';
export const pusherClient = new Pusher(process.env.PUSHER_APP_KEY, {
    cluster: 'eu'
});
pusherClient.connect();
function MyApp({ Component, ...rest }) {
    const { store, props } = wrapper.useWrappedStore(rest);
    const persistor = process.browser ? store.__persistor : store;
    const { pageProps } = props;
    return (<Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loader />}>
        <NextNProgress color="#DBB222" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow options={{ showSpinner: false }}/>
        <Component {...pageProps}/>
      </PersistGate>
    </Provider>);
}
const __Next_Translate__Page__191e5d015e0__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__191e5d015e0__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  