import { REDUX_SOCKET_TYPES } from './action'

const initialState = {
  refresh: false,
  hotNotif: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_SOCKET_TYPES.REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case REDUX_SOCKET_TYPES.NEW_NOTIF:
      return {
        ...state,
        newNotif: action.payload
      }
    case REDUX_SOCKET_TYPES.HOT_NOTIF:
      return {
        ...state,
        hotNotif: action.payload
      }
    default:
      return state
  }
}
